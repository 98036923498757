import React from 'react'
import {Link} from 'react-router-dom'

import CSSPlugin from 'gsap/CSSPlugin';
import {TweenMax, TimelineMax, Power4, Back} from 'gsap/all'
import ScrollMagic from '../lib/ScrollMagic.js'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
const myPlugins = [CSSPlugin]

export default class Collaborators extends React.Component{

	constructor(props){
		super(props)
		this.state = {collaborators: [], bioPopup: false, popupTitle: '', popupDescription: '', popupImage: ''}
		this.collaboratorDivs = []
		this.bioPopupClose = []
	}
	getCollaborators(){
		const url = '/api/collaborators'
		const params = { method: 'GET',
               headers: new Headers(),
               mode: 'cors',
               cache: 'default' };
		fetch(url, params)
			.then(response => response.json())
			.then(data => {
					this.setState({collaborators: data})
							})
			.then(() => this.setScrollMagic())
	}

	setScrollMagic(){
		this.smController = new ScrollMagic.Controller()
		const smScene1 = new ScrollMagic.Scene({
			triggerElement: this.sectionDiv,
			triggerHook: 0.5,
			reverse: false
		})
		const tween1 = TweenMax.staggerFromTo(this.collaboratorDivs, 0.5, {opacity:0, x: -100}, {opacity:1, x: 0, ease: Back.easeInOut}, 0.2)

		smScene1.setTween(tween1)
				.addTo(this.smController)
	}

	componentDidMount(){
		this.getCollaborators()
	}

	showBioPopup(id){
		let item = this.state.collaborators.find(item => item.id == id)
		this.setState({popupTitle: item.name, 
						popupDescription: item.description, 
						popupImage: item.image.url, 
						bioPopup: true }, () => {
							document.querySelector('.bio-popup_inner').scrollTop = 0
						})

		// TweenMax.to(this.bioPopup, 1, {display: 'block', autoAlpha: 1})
		document.querySelector('html').classList.add('no-scroll')
		document.querySelector('body').classList.add('no-scroll')
	}

	closeBioPopup(){
		this.setState({bioPopup: false })

		document.querySelector('html').classList.remove('no-scroll')
		document.querySelector('body').classList.remove('no-scroll')
	}
	
	render(){
		return(
			<div className="collaborators" ref={div => this.sectionDiv = div}>
				<div className="medium-container">
					<div className="section-title">
						I nostri collaboratori
						<div className="section-title_line" ></div>
					</div>
					<div className="txt-4 regular downcase">
						Tutti i nostri corsi sono seguiti, oltre che da noi,<br/>
						anche dai nostri insegnanti certificati.<br/><br/>
						Potrai conoscerli personalmente partecipando alle lezioni e ai nostri eventi!
					</div>
					<div className="collaborators-list">
						{this.state.collaborators.map(coll => {
							return(
								<a className="collaborators-list_item" 
									onClick={() => this.showBioPopup(coll.id)} 
									ref={div => this.collaboratorDivs.push(div)} 
									key={`collaborator-${coll.id}`}>
									<div className="collaborators-list_item_image">
										<picture>
											<img src={coll.image.mini.url} alt={coll.name}/>
										</picture>
									</div>
									<div className="collaborators-list_item_name">
										<div className="regular">{coll.name}</div>
									</div>
								</a>
							)
						})}
					</div>
				</div>
				<div className={"bio-popup" + (this.state.bioPopup ? ' is-active' : '' )}  ref={div => this.bioPopup = div}>
					<div className="bio-popup_inner">
						<div className="close-bio-popup txt-2" ref= {div => this.bioPopupClose.push(div)}
						onClick={() => this.closeBioPopup()}><i className="icon-close"></i></div>
						
						
						<div ref={div => this.bioPopupTitle = div}>
							<div id="js-bio-title" ref={div => this.bioPopupTitle = div} 
							className="bio-image">
								<img src={this.state.popupImage} alt='med-coll-image'/>
								<div className="bio-image_name txt-3 c-white">
									{this.state.popupTitle}
								</div>
							</div>
							<div className="txt-4 downcase c-primary-light bio-description">
								{ReactHtmlParser(this.state.popupDescription)}
							</div>
						</div>
						<div className="white-action txt-4" onClick={() => this.closeBioPopup()}
						ref= {div => this.bioPopupClose.push(div)}>chiudi</div>
					</div>
				</div>
			</div>

		)
	}
}