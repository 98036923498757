import React from 'react'
import { Helmet } from 'react-helmet';
import Home from './home/Home'
import Courses from './courses/Courses'
import About from './about/About'
import CookiePolicy from './about/CookiePolicy'
import Videos from './videos/Videos'
import SingleEvent from './events/SingleEvent'
import { BrowserRouter, Switch, Route, Link, NavLink, Router } from 'react-router-dom'
// import { TransitionGroup, Transition } from 'react-transition-group'

// import CSSPlugin from 'gsap/CSSPlugin';
// const myPlugins = [CSSPlugin]
import {TweenMax, TimelineMax, Power4, Back} from 'gsap'
import ScrollMagic from './lib/ScrollMagic.js'

import animateScrollTo from 'animated-scroll-to';

window.loadPromise = new Promise(resolve => {
  window.addEventListener('DOMContentLoaded', resolve)
})

export default class App extends React.Component {
	constructor(props){
		super(props)
	
	}

	render() {
		return (
			<div className="App">
				<Switch>
					<Route exact path="/" component={Home}/>
					<Route exact path="/corsi" component={Courses}/>
					<Route exact path="/chi-siamo" component={About}/>
					<Route exact path="/#eventi" component={Home}/>
					<Route exact path="/video" component={Videos}/>
					<Route path="/eventi/:slug" component={SingleEvent}/>
					<Route exact path="/cookie-policy" component={CookiePolicy}/>
				</Switch>
				<Helmet>
					<title>Marco e Daisy | corsi e show lindy hop e boogie woogie a Milano</title>
					<meta name="description" content="Facciamo spettacoli, corsi, workshop, insegnamo a ballerini di qualunque livello, dai più ai meno esperti."/>
					<meta property="og:title"  content="Marco e Daisy | corsi e show lindy hop e boogie woogie a Milano" />
                    <meta property="og:description" content="Facciamo spettacoli, corsi, workshop, insegnamo a ballerini di qualunque livello, dai più ai meno esperti." />
					<meta name="twitter:title" content="Marco e Daisy | corsi e show lindy hop e boogie woogie a Milano" />
    				<meta name="twitter:description" content="Facciamo spettacoli, corsi, workshop, insegnamo a ballerini di qualunque livello, dai più ai meno esperti." />
				</Helmet>
				<Footer/>
				<Header/>
			</div>			
		)
	}
}

class Header extends React.Component{
	constructor(props){
		super(props)
		this.mobileNavItems = []
		this.mobileNavLines = []
	}
	setHeaderAnimation(e){
		// DON'T USE SCROLLMAGIC - because this is isolated component and cannot refer to main-container div
		if(window.location.pathname == '/'){
			if(e.target.scrollingElement.scrollTop > 100 ){
				TweenMax.to(this.header, 0.5, {opacity: 1})
			} else {
				TweenMax.to(this.header, 0.5, {opacity: 0})
			}
		} else {
			TweenMax.to(this.header, 0.5, {opacity: 1})
		}
	}

	handleHamburgerClick(){
		if(this.hamburger.classList.contains('active') == true){
			this.closeMobileMenu()
		} else {
			this.openMobileMenu()
		}
	}

	closeMobileMenu(){
		this.hamburger.classList.remove('active')
		TweenMax.to(this.mobileNav, 0.5, {opacity: 0, onComplete: () => this.mobileNav.classList.remove('active')})
		document.querySelector('html').classList.remove('no-scroll')
		document.querySelector('body').classList.remove('no-scroll')
	}

	openMobileMenu(){
		this.hamburger.classList.add('active')
		this.mobileNav.classList.add('active')
		document.querySelector('html').classList.add('no-scroll')
		document.querySelector('body').classList.add('no-scroll')
		TweenMax.to(this.mobileNav, 0.5, {opacity: 1, ease: Power4.easeInOut})
		TweenMax.staggerFromTo(this.mobileNavItems, 1, {opacity: 0, x: -20}, {opacity: 1, x: 0, ease: Power4.easeInOut}, 0.1)
		TweenMax.staggerFromTo(this.mobileNavLines, 1, {width: 0, opacity: 0}, {opacity: 1, width: '40px', ease: Back.easeInOut.config(8), delay: 0.5}, 0.1)
	}

	componentDidMount(){
		
		document.addEventListener('scroll', (e) => this.setHeaderAnimation(e))
		if(window.location.pathname != '/' || window.location.hash.length > 3){
			TweenMax.to(this.header, 0.5, {opacity: 1})
		}
	}
	componentWillUnmount(){
		document.removeEventListener('scroll', (e) => this.setHeaderAnimation(e))
	}

	scrollToEvents(){
		if(window.location.pathname == '/'){
			animateScrollTo(document.querySelector('#home-events'))
		}
	}
		

	render(){

		return (
			<div className="header" ref={div => this.header = div}>
				<Link to="/" className="header_logo">
						<img src="/contents/icona.svg" alt="med-icona"/>
				</Link>
				<div className="header_nav">
					<NavLink activeClassName="is-active" exact to="/" className="header_nav_item">home</NavLink>
					<NavLink activeClassName="is-active" exact to="/chi-siamo" className="header_nav_item">chi siamo</NavLink>
					<NavLink activeClassName="is-active" exact to="/corsi" className="header_nav_item">corsi</NavLink>
					<NavLink exact to="/#eventi" className="header_nav_item" onClick={() => this.scrollToEvents()}>eventi</NavLink>
					<NavLink activeClassName="is-active" exact to="/video" className="header_nav_item">video</NavLink>
				</div>
				<div ref={div => this.hamburger = div} className="header_hamburger" onClick={() => this.handleHamburgerClick()}>
					<span></span>
					<span></span>
					<span></span>
				</div>
				
				<div ref={div => this.mobileNav = div} className="header_mobile-nav">
					<div className="header_mobile-nav_logo">
						<img src="/contents/logo-esteso.svg" alt="marcoedaisy-logo"/>
					</div>
					<NavLink to="/" className="header_mobile-nav_item" onClick={() => this.closeMobileMenu()} 
						  ref={div => this.mobileNavItems.push(div)}>
						home
					</NavLink>
					<div className="header_mobile-nav_line" ref={div => this.mobileNavLines.push(div)}>
					</div>
					<NavLink to="/chi-siamo" className="header_mobile-nav_item" onClick={() => this.closeMobileMenu()} 
						  ref={div => this.mobileNavItems.push(div)}>
						chi siamo
					</NavLink>
					<div className="header_mobile-nav_line" ref={div => this.mobileNavLines.push(div)}>
					</div>
					<NavLink to="/corsi" className="header_mobile-nav_item" onClick={() => this.closeMobileMenu()} 
						  ref={div => this.mobileNavItems.push(div)}>
						corsi
					</NavLink>
					<div className="header_mobile-nav_line" ref={div => this.mobileNavLines.push(div)}>
					</div>
					<NavLink to="/#events" className="header_mobile-nav_item"  
						onClick={() => {
									this.closeMobileMenu()
									this.scrollToEvents()
									}
								}
						  ref={div => this.mobileNavItems.push(div)}>
						eventi
					</NavLink>
					<div className="header_mobile-nav_line" ref={div => this.mobileNavLines.push(div)}>
					</div>
					<NavLink to="/video" className="header_mobile-nav_item"  onClick={() => this.closeMobileMenu()}
						  ref={div => this.mobileNavItems.push(div)}>
						video
					</NavLink>
				</div>


				
			</div>)
	}
}

class Footer extends React.Component{

	closeCookiePopup() {
		let cookiePopup = document.querySelector('.cookie-popup')
		cookiePopup.classList.add('hidden')
		document.cookie = "marcoedaisy_cookie_agreed=yes; SameSite=lax"
	}

	componentDidMount() {
		if (document.cookie.indexOf("marcoedaisy_cookie_agreed=yes") < 0){
			let cookiePopup = document.querySelector('.cookie-popup')
			setTimeout(() => {cookiePopup.classList.remove('hidden')}, 1000)
		}
	}
	
	render(){
		const year = new Date().getFullYear()
		return (
			<div>
				<div className="footer">
					<div className="medium-container">
						<div className="footer_container">
							<Link to="/"  className="footer_logo">
								<img src="/contents/logo-oriz-white.svg" alt="marcoedaisy-logo"/>
							</Link>
							<div className="footer_site-sections">
								<div className="footer_site-sections_title"></div>
							</div>
							<div className="footer_next-events">
							</div>
							<div className="footer_follow-us">
								<a href="https://www.facebook.com/marcoedaisyteam/" target="_blank">
									<i className="icon-facebook"></i>
								</a>
								<a href="https://www.instagram.com/marcoedaisy/?hl=it" target="_blank">
									<i className="icon-instagram"></i>
								</a>
								<a href="https://www.youtube.com/channel/UCcx45clLRsyglGIEpZXk3DQ" target="_blank">
									<i className="icon-youtube"></i>
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className="post-footer">
					<div className="medium-container">
						<div className="post-footer">
							<div className="txt-6 c-white bold">© {year} - Marco & Daisy</div>
							<NavLink to="/cookie-policy" className="txt-6 c-white downcase">
							Cookie-policy
							</NavLink>  
						</div>
					</div>
				</div>
				<div className="cookie-popup hidden">
					<div className="txt-6 bold">
						Questo sito utilizza i cookie
					</div>
					<div className="txt-7 downcase">
						<NavLink to="/cookie-policy" className="txt-7 c-white downcase">
							cookie policy
						</NavLink>  
						<div className="action txt-7 bold uppercase margin-left-2" onClick={this.closeCookiePopup}>
						prosegui
					</div>	
					</div>	
					
				</div>
			</div>
			)
	}
}