import React from 'react'

import YoutubeEmbedVideo from 'youtube-embed-video'

export default class Videos extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			videos: []
		}
	}

	getVideos(){
		const url = '/api/videos'
		const params = { method: 'GET',
               headers: new Headers(),
               mode: 'cors',
               cache: 'default' };
		fetch(url, params)
			.then(response => response.json())
			.then(data => this.setState({videos: data}))
	}

	componentDidMount(){
		window.scrollTo(0, 0)
		this.getVideos()
	}

	render(){

		return(
			<div className="main-container">
				<div className="medium-container">
					<div className="page-title">Video</div>
					<div className="single-video-container">
						{this.state.videos.map(video => {
							return(
								<div key={`video-${video.id}`} className="single-video-container_item">
									<div className="single-video-container_item_video">
							      		<YoutubeEmbedVideo videoId={video.embed_code} 
							      		suggestions={false}
							      		showInfo={false}
							      		/>
						      		</div>
						      		<div className="single-video-container_item_data">
							      		{video.title}<br/>
							      		{video.date} - {video.location}
							      	</div>
						    	</div>)
						})}
					</div>
				</div>
			</div>
		)
	}
}