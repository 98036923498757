import React from 'react'
import {Link} from 'react-router-dom'

// import CSSPlugin from 'gsap/CSSPlugin';
// const myPlugins = [CSSPlugin]
import {TweenMax, TimelineMax, Power4, Back} from 'gsap'
import ScrollMagic from '../lib/ScrollMagic.js'

import animateScrollTo from 'animated-scroll-to';

import {setParallax} from '../lib/utils.js'

export default class Intro extends React.Component{
	constructor(props){
		super(props)
		this.staggerWords = []
		this.staggerWords2 = []
		this.smController = new ScrollMagic.Controller()
	}


	setSectionAnimation(){
		const smScene1 = new ScrollMagic.Scene({
			triggerElement: this.sectionDiv,
			triggerHook: 1,
			reverse: false
		})

		const tl = new TimelineMax()
		tl.add("startWords", 0.2)
			.staggerFromTo(this.staggerWords, 1, 
							{y: -5, opacity:0}, 
							{y: 0, opacity: 1, delay: 0.5}, 
							0.1, "startWords")
		  	.add("startLine", 1)
		  	.staggerFromTo([this.introTitleLine, this.introTitleLineOver], 1, 
		  					{width: "0%"}, 
		  					{width: "100%", ease: Back.easeOut.config(2)}, 
		  					0.1, "startLine")
		  	.play()
	
		smScene1.setTween(tl)
				.addTo(this.smController)

		const smScene2 = new ScrollMagic.Scene({
			triggerElement: this.threeBallsDiv,
			triggerHook: 1,
			reverse: false
		})

		const tween = TweenMax.staggerFromTo(this.staggerWords2, 0.5, 
							{y: -5, opacity:0}, 
							{y: 0, opacity: 1, delay: 0.5}, 
							0.2)
	
		smScene2.setTween(tween)
				.addTo(this.smController)
	}


	componentDidMount(){
		this.setSectionAnimation()
		setParallax(this.sectionDiv, this.sectionBg, '1', '400%', '-30%', '60%'); 
	}

	scrollToElement(id){
		animateScrollTo(document.querySelector(id));
	}

	render(){
		return(
			<section className="intro" ref={div => this.sectionDiv = div}>
				<div ref={div => this.sectionBg = div} className="intro_image">
					<img className="image-darken-light" 
						 alt="Marco & Daisy Acrobatic" title="Marco & Daisy Acrobatic"
						 src="/contents/med-acrobatic.png"/>
				</div>
				<div className="intro-title" ref={div => this.introTitle = div}>
					<div className="intro-title_image">
						<img  src="contents/logo-oriz-white.svg" alt="marco-e-daisy-logo"/>
						<div className="intro-title_image_line" ref={div => this.introTitleLine = div}></div>
						<div className="intro-title_image_line-over" ref={div => this.introTitleLineOver = div}></div>
					</div>
					<div className="intro-title_text txt-2 regular">
						<span ref={div => this.staggerWords.push(div)}>lindy</span>
						<span ref={div => this.staggerWords.push(div)}> hop</span>
						<span className="c-accent-blue" ref={div => this.staggerWords.push(div)}>&nbsp; & &nbsp;</span>
						<span ref={div => this.staggerWords.push(div)}>boogie</span>
						<span ref={div => this.staggerWords.push(div)}> woogie</span>
						<br/>
						<span ref={div => this.staggerWords.push(div)}>dance teachers</span>
						<br/>
						<div className="intro-title_text_last_line">
							<span className="intro-title_text_line bg-accent-red" ref={div => this.staggerWords.push(div)}></span> 
							<span ref={div => this.staggerWords.push(div)}>Milano</span>
							<span className="intro-title_text_line bg-accent-red" ref={div => this.staggerWords.push(div)}></span> 
						</div>
					</div>

					<div className="intro-title_text_bio txt-4 light">
						<h1>Marco e Daisy <br/>
										ballerini e insegnanti boogie woogie e lindy
						 				hop<br/> a Milano
						 </h1><br/>
					</div>
					<p className="intro-title_text_bio txt-4 light">
						<span>Facciamo spettacoli, corsi, workshop,</span><br/> 
						<span>insegnamo a ballerini di qualunque livello,<br/> dai più ai meno esperti.</span><br/>
						<br/>
						<span>Non siamo solo un gruppo di ballo, ma siamo una grande famiglia.</span>
						<br/>
						<span className="italic">"Senza l'entusiasmo non è stato compiuto mai nulla di grande"</span>
					</p>
				</div>
				<div className="small-container">
					<div className="intro_three-balls" ref={div => this.threeBallsDiv = div}>
						<div className="intro_three-balls_item">
							<div className="intro_three-balls_item_main txt-2 special">
								Eventi
							</div>
							<div className="intro_three-balls_item_description txt-5">
								Partecipa ai nostri spettacoli o contattaci per il tuo evento
							</div>
							<div className="intro_three-balls_item_action txt-5" onClick={() => this.scrollToElement('#home-events')}>
								scopri di più
							</div>
						</div>
						<div className="intro_three-balls_item">
							<div className="intro_three-balls_item_main txt-2 special">
								Corsi
							</div>
							<div className="intro_three-balls_item_description txt-5">
								Iscriviti ai nostri corsi o contattaci per lezioni private di boogie woogie e lindy hop
							</div>
							<div className="intro_three-balls_item_action txt-5" onClick={() => this.scrollToElement('#home-courses')}>
								scopri di più
							</div>
						</div>
						<div className="intro_three-balls_item">
							<div className="intro_three-balls_item_main txt-2 special">
								Gare
							</div>
							<div className="intro_three-balls_item_description txt-5">
								Scendi in gara nel nostro team in competizioni nazionali e internazionali
							</div>
							<div className="intro_three-balls_item_action txt-5" onClick={() => this.scrollToElement('#home-team')}>
								scopri di più
							</div>
						</div>
					</div>
				</div>
				
			</section>
		)
	}
}