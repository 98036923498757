import React from 'react'
import { Helmet } from 'react-helmet';
import {setParallax} from '../lib/utils.js'
export default class About extends React.Component{
	constructor(props){
		super(props)
	}

	componentDidMount(){
		window.scrollTo(0, 0)
		setParallax(this.sectionDiv, this.sectionBg, '1', '400%', '0%', '60%'); 
	}
	render(){
		return(<div className="main-container about" ref={div => this.sectionDiv = div}>
				<Helmet>
                    <title>Marco e Daisy | insegnanti lindy hop e boogie woogie a Milano</title>
                    <meta name="description" content="Abbiamo cominciato a ballare da bambini, dai 15 anni siamo entrati a far parte di un Gruppo Coreografico dove, oltre al Boogie Woogie..."/>
                	<meta property="og:title"  content="Marco e Daisy | corsi e show lindy hop e boogie woogie a Milano" />
                    <meta property="og:description" content="Abbiamo cominciato a ballare da bambini, dai 15 anni siamo entrati a far parte di un Gruppo Coreografico dove, oltre al Boogie Woogie..." />
					<meta name="twitter:title" content="Marco e Daisy | corsi e show lindy hop e boogie woogie a Milano" />
    				<meta name="twitter:description" content="Abbiamo cominciato a ballare da bambini, dai 15 anni siamo entrati a far parte di un Gruppo Coreografico dove, oltre al Boogie Woogie..." />
                </Helmet>
					<div className="medium-container">
						<div className="page-title">Chi Siamo</div>
						<div className="about-container downcase">
							<div className="txt-5 about-text-block">Abbiamo cominciato a ballare da bambini, dai 15 anni siamo entrati a far parte di un Gruppo Coreografico dove, oltre al Boogie Woogie, abbiamo studiato Rock'n'roll Acrobatico, Disco Dance, Musicals, Hip Hop e House.</div>
							<div className="txt-5 about-text-block">Dal 2011 abbiamo deciso di concentrarci solamente sul mondo Swing.<br/>I risultati che abbiamo ottenuto ci hanno confermato di aver fatto la scelta giusta! In quell'anno abbiamo vinto i Campionati Regionali, i Campionati Italiani e i Campionati Italiani Assoluti e siamo arrivati in semifinali e finali nelle Coppe del Mondo.</div>
							<div className="txt-5 about-text-block">Negli anni a seguire abbiamo continuato a raggiungere ottimi risultati sia nelle competizioni nazionali che internazionali.</div>
							<div className="txt-5 about-text-block">Nel 2016 abbiamo deciso di terminare le competizioni per dedicarci completamente all'insegnamento e fare della nostra passione il nostro lavoro.<br/>Abbiamo creato un fantastico team agonistico ottenendo ottimi risultati,  insegnamo in festival ed eventi e balliamo nelle principali serate swing e rock'n'roll italiane.<br/>Sentire gli applausi del pubblico, trasmettere a chi ci guarda parte del nostro entusiasmo, far ballare chiunque e poter insegnare a tutti a muoversi sulla musica.. questo è ciò che ci rende felici!</div>

						</div>
						<div className="about-bg" ref={div => this.sectionBg = div}>
							<img className="image-darken-light" src="/contents/about-bg.png" alt="marco-e-daisy-chi-siamo"/>
						</div>
						<div className="intro-title">
							<div className="intro-title_image">
								<img  src="contents/logo-oriz-white.svg" alt="marco-e-daisy-logo"/>
								<div className="intro-title_image_line"></div>
								<div className="intro-title_image_line-over"></div>
							</div>
							<div className="intro-title_text txt-2 regular">
								<span>lindy hop</span>
								<span className="c-accent-blue">&nbsp; & &nbsp;</span>
								<span>boogie woogie</span>
								<br/>
								<span>dance teachers</span>
								<br/>
								<div className="intro-title_text_last_line">
									<span className="intro-title_text_line bg-accent-red"></span> 
									<span>Milano</span>
									<span className="intro-title_text_line bg-accent-red"></span> 
								</div>
							</div>
						</div>
						<div className="panel-contacts">
                            <div className="txt-3 c-white regular">
                                Contatti:
                            </div>
                            <div className="panel-contacts_list txt-4">
                                <a className="panel-contacts_list_item" href="https://wa.me/393899121794" target="_blank">
                                    <i className="icon-whatsapp1"></i> +39 3899121794 
                                </a>
                                <a className="panel-contacts_list_item" href="https://wa.me/393407978472" target="_blank">
                                    <i className="icon-whatsapp1"></i> +39 3407978472 
                                </a>
                                <a className="panel-contacts_list_item" href="mailto:info.marcoedaisy@gmail.com" target="_blank">
                                    <i className="icon-mail"></i> info.marcoedaisy@gmail.com 
                                </a>
                            </div>
                        </div>
					</div>
				</div>)
	}
}