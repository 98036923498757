import React from 'react'
import { BrowserRouter as Router, Switch, Route, Link, HashRouter } from 'react-router-dom'

import CSSPlugin from 'gsap/CSSPlugin';
import {TweenMax, TweenlineMax, Power4, Back} from 'gsap'
import ScrollMagic from '../lib/ScrollMagic.js'
const myPlugins = [CSSPlugin]

import {setParallax, lazyLoad} from '../lib/utils.js'

export default class HomeCourses extends React.Component{
	constructor(props){
		super(props)
		this.staggerWords = []
		this.imagesDance = []
		this.smController = new ScrollMagic.Controller()
	}

	setAnimation(){
		const smScene1 = new ScrollMagic.Scene({
			triggerElement: this.sectionDiv,
			triggerHook: 0.8,
			reverse: false
		})
		
		const tween1 = TweenMax.staggerFromTo(this.staggerWords, 1, {opacity: 0, x: -100}, {opacity:1, x: 0, ease: Back.easeInOut}, 0.05)
		smScene1.setTween(tween1)
				.addTo(this.smController)

	}

	componentDidMount(){
		this.setAnimation()
		setParallax(this.sectionDiv, this.sectionBg, '1', '300%', '-20%', '60%'); 
		lazyLoad()
	}

	render(){
		return(
			<section id="home-courses" className="home-courses" ref={div => this.sectionDiv = div}>
				<div className="home-courses_bg" ref={div => this.sectionBg = div}>
				<picture>
					<source srcSet="/contents/med-posa.png" media="(min-width: 900px)"/>
					<img className="image-darken" src="/contents/med-posa-mob.png" alt="med-posa-mob"/>
				</picture>
				</div>
				<div className="medium-container">
					<div className="home-courses_contents">
						<h2 className="section-title">
							I nostri corsi
							<div className="section-title_line" ref={div => this.staggerWords.push(div)}></div>
						</h2>
						<div className="home-courses_action">
							
							<Link to="/corsi" className="button-main-action" ref={div => this.imagesDance.push(div)}>
								Vai ai corsi <i className="icon-chevron-thin-right"></i>
							</Link>
							
						</div>
						<h4 className="home-courses_intro downcase">
							<span className="txt-5">
								Se vuoi iniziare a muovere i primi passi o vuoi diventare un esperto nel boogie woogie e nel lindy hop partecipa alle nostre lezioni,
							</span>
							<span className="txt-5 margin-bottom-5">
								non essere timido!
							</span>
							<span className="txt-5 margin-bottom-5 c-white">
								Vieni a trovarci, la prima lezione è GRATUITA.
							</span>

							<span className="txt-5 margin-bottom-5">
								Abbiamo corsi per tutti i livelli di esperienza, dal livello base ai più avanzati.
							</span>
							<span className="txt-5">
								...e se ami la sfida, entra nel Marco & Daisy team e parteciperai a competizioni internazionali
							</span>
						</h4>

						<div className="panel-contacts">
							<div className="txt-3 c-white regular">
								Contattaci subito e iscriviti!
							</div>
							<h5 className="txt-5 downcase">
								Ti consiglieremo il corso che fa per te e la location più comoda per raggiungerci a Milano e non solo!
							</h5>
							<div className="panel-contacts_list txt-4">
								<a className="panel-contacts_list_item" href="https://wa.me/393899121794" rel="nofollow" target="_blank">
									<i className="icon-whatsapp1"></i> +39 3899121794 
								</a>
								<a className="panel-contacts_list_item" href="https://wa.me/393407978472" rel="nofollow" target="_blank">
									<i className="icon-whatsapp1"></i> +39 3407978472 
								</a>
								<a className="panel-contacts_list_item" href="mailto:info.marcoedaisy@gmail.com" rel="nofollow" target="_blank">
									<i className="icon-mail"></i> info.marcoedaisy@gmail.com 
								</a>
							</div>
						</div>
						
						
						<div className="home-courses_action">
							
							<Link to="/corsi" className="button-main-action" ref={div => this.imagesDance.push(div)}>
								Vai ai corsi <i className="icon-chevron-thin-right"></i>
							</Link>
							
						</div>
					</div>
					<HomeCoursesLocations />
				</div>
				
			</section>
		)
	}
}


class HomeCoursesLocations extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			locations: []
		}
		this.locationImages = []
	}

	getLocations(){
		const url = '/api/locations'
		const params = { method: 'GET',
               headers: new Headers(),
               mode: 'cors',
               cache: 'default' };
		fetch(url, params)
			.then(response => response.json())
			.then(data => this.setState({locations: data}))
			.then(() => this.setScrollMagic())
	}

	componentDidMount(){
		this.getLocations()
	}

	setScrollMagic(){
		this.smController = new ScrollMagic.Controller()
		const smScene2 = new ScrollMagic.Scene({
			triggerElement: this.locationsDiv,
			triggerHook: 0.9,
			reverse: false
		})
		
		const tween1 = TweenMax.staggerFromTo(this.locationImages, 1, {opacity: 0, x: -100}, {opacity:1, x: 0, ease: Back.easeInOut}, 0.05)
		smScene2.setTween(tween1)
				.addTo(this.smController)
	}

	render(){
		return(
			<div className="home-courses_locations" ref={div => this.locationsDiv}>
				<div className="txt-2 c-white home-courses_locations_title">
					dove sono i nostri corsi ?
					<div className="underline"></div>
				</div>
				<div className="home-courses_locations_list">
					{this.state.locations.map(location => {
						return(
							<div className="home-courses_locations_list_item" 
								 ref={div => this.locationImages.push(div)}
								 key={`location-${location.id}`}>
								<img src={location.image.url} alt={"location-"+ location.title}/>
								<div className="home-courses_locations_list_item_text">
									<div className="txt-5">{location.title}</div>
									<div className="txt-5 c-white home-courses_locations_list_item_text_city">{location.city}</div>
								</div>
							</div>
						)
					})}
				</div>
			</div>
		)
	}
}