import React from 'react'
import { Helmet } from 'react-helmet';
import {GoogleMap, withScriptjs, withGoogleMap, Marker} from 'react-google-maps' 

export default class SingleEvent extends React.Component{
	constructor(props){
		super(props)
		this.state = {
			event: null,
            lat: null,
            lng: null
		}
	}

	getEvent(slug){
		const url = `/api/single-event/${slug}`
		const params = { method: 'GET',
               headers: new Headers(),
               mode: 'cors',
               cache: 'default' };
		fetch(url, params)
			.then(response => response.json())
			.then(data => {
                this.checkLatLng(data)
                })

    }

    checkLatLng(data){
        if (data.latitude == null || data.longitude == null){
            // let string = this.state.event.address
            let string = data.address
            let url = `https://maps.googleapis.com/maps/api/geocode/json?address=${string.replace(" ", "+")}&key=AIzaSyDWjBNQIcqOttNtugprTDl3PNWRQAHJips`
            const params = { method: 'GET',
                   headers: new Headers(),
                   mode: 'cors',
                   cache: 'default' };
            fetch(url, params)
                .then(response => response.json())
                .then(results => this.setState({event: data, 
                                            lat:results.results[0].geometry.location.lat,
                                            lng:results.results[0].geometry.location.lng }, () => console.log('state', this.state)))
        } else {
            this.setState({event: data, lat:data.latitude, lng: data.longitude})
        }
    }

	componentWillMount(){
		this.getEvent(this.props.match.params.slug)
	}
    componentDidMount(){
        window.scrollTo(0, 0)
    }
	render(){
		return(
			<div className="main-container single-event">

				{this.state.event ? <EventMap event={this.state.event} lat={this.state.lat} lng={this.state.lng}/> : ''}
				{this.state.event ? <EventInfo event={this.state.event}/> : ''}
			</div>)
	}
}


class EventInfo extends React.Component{
	constructor(props){
		super(props)
        this.monthNames = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']
	}
    monthName(date_coded){
        let date = Date.parse(date_coded)
        date = new Date(date)
        let monthName = this.monthNames[date.getMonth()]
        return monthName
    }
    dayNumber(date_coded){
        let date = Date.parse(date_coded)
        date = new Date(date)
        let number = date.getDate()
        if (number < 10){
            number = '0' + number
        }
        return number
    }
    timing(date_coded){
        let date = Date.parse(date_coded)
        date = new Date(date)
        let hours = parseInt(date.getHours()) - 1
        let minutes = date.getMinutes()
        minutes = ('0' + minutes).slice(-2) 
        return hours + ':' + minutes
    }

	render(){

		return(<div className="single-event-info">
                <Helmet>
                    <title>Marco e Daisy | {this.props.event.title}</title>
                    <meta name="description" content="Venite a trovarci a {this.props.event.city} il {this.dayNumber(this.props.event.start_date)} {this.monthName(this.props.event.start_date)} al {this.props.event.venue}. "/>
                    <meta property="og:title"  content="Marco e Daisy | {this.props.event.title}" />
                    <meta property="og:description" content="Venite a trovarci a {this.props.event.city} il {this.dayNumber(this.props.event.start_date)} {this.monthName(this.props.event.start_date)} al {this.props.event.venue}." />
                    <meta name="twitter:title" content="Marco e Daisy | {this.props.event.title}" />
                    <meta name="twitter:description" content="Venite a trovarci a {this.props.event.city} il {this.dayNumber(this.props.event.start_date)} {this.monthName(this.props.event.start_date)} al {this.props.event.venue}." />
                </Helmet>
					<div className="single-event-info_title">
                        <h1 className="txt-2 regular single-event-info_title_main c-white regular">
                            {this.props.event.title}
                        </h1>
                        <div className="single-event-info_title_date txt-3">
                            {this.dayNumber(this.props.event.start_date)} {this.monthName(this.props.event.start_date)} - {this.props.event.city}
                        </div>
					</div>
					<div className="single-event-info_details">
                        <div className="txt-4">location: <span className="c-white regular">{this.props.event.venue}</span>
    					</div>
                        <div className="txt-4">indirizzo: <span className="c-white regular">{this.props.event.address}</span>
                        </div>
                        <div className="txt-4">orario: <span className="c-white regular">{this.timing(this.props.event.time)}</span>
                        </div>
                        {this.props.event.description ? <div className="txt-4">info: <span className="c-white regular"><div
  dangerouslySetInnerHTML={{
    __html: this.props.event.description
  }}></div> </span></div> : ''}
                        {this.props.event.link ? <a href={this.props.event.link} className="single-event-link" rel="nofollow" target="_blank">Vedi dettagli</a> : ''}
                    </div>
				</div>)
	}
}

class EventMap extends React.Component{

    render(){
		return(<div className="single-event-map">
					<MapComponent ref="mapComponent"
                            lat={parseFloat(this.props.lat)}
                            lng={parseFloat(this.props.lng)} 
                            googleMapURL = {"https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyDWjBNQIcqOttNtugprTDl3PNWRQAHJips"}
                            loadingElement = {<div className="single-event-map_map"  />}
                            containerElement = {<div className="single-event-map_map"  />}
                            mapElement = {<div style={{height: "100%" }}/>} 
                        />
				</div>)
	}
}

function Map(props){
    // const mapStyles = [{"featureType":"all","elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#000000"},{"lightness":40}]},{"featureType":"all","elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#000000"},{"lightness":16}]},{"featureType":"all","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#000000"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#000000"},{"lightness":17},{"weight":1.2}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#000000"},{"lightness":20}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#000000"},{"lightness":21}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#000000"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#000000"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#000000"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#000000"},{"lightness":16}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#000000"},{"lightness":19}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#000000"},{"lightness":17}]}]
    const mapOptions = {disableDefaultUI: true, styles: mapStyles}
    let center = {}

    if(window.innerWidth > 575){
        center = {lat: props.lat, lng: props.lng - 0.03}
    } else {
        center = {lat: props.lat + 0.03, lng: props.lng}
    }
	return <GoogleMap 
            style="width: 100%; "
            defaultZoom={13} 
            defaultCenter={center}
            defaultOptions={mapOptions}
            >
            <Marker position={{lat: props.lat, lng: props.lng}} />
        </GoogleMap>
}

const MapComponent = withScriptjs(withGoogleMap(Map))



const mapStyles = [
    {
        "featureType": "all",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "saturation": 36
            },
            {
                "color": "#000000"
            },
            {
                "lightness": 40
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#000000"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#fbba20"
            },
            {
                "lightness": "13"
            },
            {
                "weight": "0.32"
            },
            {
                "gamma": "0.68"
            },
            {
                "saturation": "-41"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 17
            },
            {
                "weight": 1.2
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "saturation": "0"
            },
            {
                "lightness": "10"
            },
            {
                "gamma": "0.93"
            },
            {
                "weight": "0.49"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "weight": "0.49"
            },
            {
                "lightness": "0"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000a14"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000a14"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": "22"
            },
            {
                "gamma": "1.76"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 29
            },
            {
                "weight": 0.2
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": "17"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "weight": "0.66"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "weight": "0.43"
            },
            {
                "gamma": "1.59"
            },
            {
                "color": "#f7e3b4"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 10
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "weight": "0.75"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#000000"
            },
            {
                "lightness": 10
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#25484d"
            }
        ]
    }
]