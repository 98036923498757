import React from 'react'
import { NavLink } from 'react-router-dom'

// import CSSPlugin from 'gsap/CSSPlugin';
// const myPlugins = [CSSPlugin]
import {TweenMax, TimelineMax, Power4, Back} from 'gsap'
import ScrollMagic from '../lib/ScrollMagic.js'

import {setParallax} from '../lib/utils.js'
import animateScrollTo from 'animated-scroll-to'; // scroll page to events


export default class Hero extends React.Component{

	constructor(props){
		super(props)
		this.subHeaderNav = []
		this.smcontroller = new ScrollMagic.Controller()
	}

	setLogoTl(){
		// const tlLogo = new TimelineMax({repeat: -1}) // eliminated repeat
		const tlLogo = new TimelineMax()
		tlLogo.fromTo(this.heroLogo, 1, {opacity: 0, x: -100}, {opacity: 1, x: 0, ease: Power4.easeInOut}, 11) // piccolo ritardo dopo la scomparsa del logo dal video
			  // .to(this.heroLogo, 1, {opacity: 0, x: -100, ease: Power4.easeInOut}, 32)
			  .play()
	}

	setVideoFilterAnimation(){
		const tween = TweenMax.to(this.videoContainer, 1, {'-webkit-filter':'grayscale(100%)',
		 										'filter':'grayscale(100%)', opacity: 0.5})

		const scene = new ScrollMagic.Scene({
			triggerElement: this.sectionDiv,
			triggerHook: 0,
			offset: "100px",
			reverse: true
		})
		scene.setTween(tween)
		scene.addTo(this.smcontroller)
	}

	setVideoSizeAnimation(){
		TweenMax.to(this.heroVideo, 5, {height: '55%', y: '-25%', ease: Power4.easeInOut, delay: 2})
	}

	setSubHeaderAnimation(){
		// this first timeline is needed when page loaded
		const tl = new TimelineMax()
		
		tl.staggerTo(this.subHeaderNav, 0.5, { autoAlpha: 0, x: -100, ease: Power4.easeInOut}, 0.1)
		tl.to(this.subHeader, 0.3,{autoAlpha: 0, ease: Power4.easeInOut, immediateRender: true})
		tl.play()
		const scene = new ScrollMagic.Scene({ 
			triggerElement: this.sectionDiv,
			triggerHook: 0,
			offset: "80px",
			reverse: true
		})
		scene.setTween(tl)
		.addTo(this.smcontroller)
		tl.play()
	}

	componentDidMount(){
		this.setLogoTl()
		this.setSubHeaderAnimation()
		setParallax(this.heroDiv, this.videoContainer, '0', '300%', '0%', '80%')
		if(window.innerWidth < 575){
			this.setVideoSizeAnimation()
		}
		this.setVideoFilterAnimation()
	}

	scrollToElement(id){
		animateScrollTo(document.querySelector(id));
	}


	render(){
		return(
			<div className="hero-container" ref={div => this.sectionDiv = div}>
				<div className="sub-header header" ref={div => this.subHeader = div}>
					<div className="header_logo">
						<img src="/contents/icona.svg" ref={div => this.subHeaderNav.push(div)} alt="med-icon"/>
					</div>
					<div className="sub-header_nav regular">
						<NavLink activeClassName="is-active" exact to="/chi-siamo" className="sub-header_nav_item"
							ref={div => this.subHeaderNav.push(div)}>chi siamo</NavLink>
						<NavLink activeClassName="is-active" exact to="/corsi" className="sub-header_nav_item" 
							ref={div => this.subHeaderNav.push(div)}>corsi</NavLink>
						<div className="sub-header_nav_item" onClick={() => this.scrollToElement('#home-events')}
							ref={div => this.subHeaderNav.push(div)}>eventi</div>
						<NavLink activeClassName="is-active" exact to="/video" className="sub-header_nav_item"
							ref={div => this.subHeaderNav.push(div)}>video</NavLink>
					</div>
					<div className="sub-header_icons">
						<a href="https://www.facebook.com/marcoedaisyteam/"  rel="nofollow" target="_blank" ref={div => this.subHeaderNav.push(div)}><i className="icon-facebook" ></i></a>
						<a href="https://www.instagram.com/marcoedaisy"  rel="nofollow" target="_blank" ref={div => this.subHeaderNav.push(div)}><i className="icon-instagram" ></i></a>
						<a href="https://www.youtube.com/channel/UCcx45clLRsyglGIEpZXk3DQ"  rel="nofollow" target="_blank" ref={div => this.subHeaderNav.push(div)}>
							<i className="icon-youtube"></i>
						</a>
					</div>
				</div>
				<div className="video-container">
					<div className="video-container_inner" ref={div => this.videoContainer = div}>
						<video loop="loop" autoPlay playsInline muted preload="none" ref={div => this.heroVideo = div}>
							<source type="video/mp4" src="/contents/video-home.mp4" />
						</video>
					</div>	
					
				</div>
				<div className="scroll-hint"><i className="icon-chevron-thin-right"></i></div>
				<div className="logo-hero" ref={div => this.heroLogo = div}>
					<img src="/contents/logo-oriz-white.svg" alt="marcoedaisy-logo-oriz"/>
				</div>
			</div>



		)
	}
}
