import React from 'react'
import {Link} from 'react-router-dom'

// import CSSPlugin from 'gsap/CSSPlugin';
// const myPlugins = [CSSPlugin]
import {TweenMax, Power4} from 'gsap'

import Hero from './Hero.jsx'
import Intro from './Intro.jsx'
import HomeCourses from './HomeCourses.jsx'
import HomeEvents from './HomeEvents.jsx'
import Collaborators from './Collaborators.jsx'
import Team from './Team.jsx'
import animateScrollTo from 'animated-scroll-to';

export default class Home extends React.Component{
	
	componentDidMount(){
		let homeEvents = document.querySelector('#home-events')
		window.scrollTo(0, 0)
		TweenMax.fromTo(this.homeDiv, 2, {opacity: 0}, {opacity: 1, ease: Power4.easeInOut})
		if (window.location.hash == '#eventi'){
			console.log(window.location.hash)
			animateScrollTo(homeEvents);
		}
	}

	render(){
		return (
			<div className="Home main-container" ref={div => this.homeDiv = div}>
				<Hero/>
				<Intro />
				<HomeEvents />
				<HomeCourses />
				<Collaborators />
				<Team />
			</div>
		)
	}
}
//
//				


				