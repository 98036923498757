import React from 'react'
import {Link} from 'react-router-dom'

import CSSPlugin from 'gsap/CSSPlugin';
import {TweenMax, TweenlineMax, Power4, Back} from 'gsap'
import ScrollMagic from '../lib/ScrollMagic.js'

const myPlugins = [CSSPlugin]

import {setParallax} from '../lib/utils.js'

export default class HomeEvents extends React.Component{
	constructor(props){
		super(props)
		this.state = {events: []}
		this.smController = new ScrollMagic.Controller()
		this.staggerWords = []
	}

	setScrollMagic(){
		const smScene1 = new ScrollMagic.Scene({
			triggerElement: this.sectionDiv,
			triggerHook: 0.8,
			reverse: false
		})
	
		const tween3 = TweenMax.staggerFromTo(this.staggerWords, 0.5, {opacity: 0, x: -100}, {opacity:1, x: 0, ease: Back.easeInOut}, 0.05)

		smScene1.setTween(tween3)
				.addTo(this.smController)
	}

	componentDidMount(){
		setParallax(this.sectionDiv, this.sectionBg, '1', '400%', '-30%', '60%'); 
		this.setScrollMagic()
	}

	render(){
		return(

			<section id="home-events" className="home-events" ref={div => this.sectionDiv = div}>
			<picture>
				<source srcSet="/contents/med-home-events-background.png" media="(min-width: 575px)"/>
				<img className="home-events_background image-darken-light" ref={div => this.sectionBg = div} 
					src="/contents/med-home-events-background-mob.png" alt="med-events-background"/>
			</picture>
				<div className="medium-container">
					<div className="section-title" ref={div => this.staggerWords.push(div)}>
						Eventi
						<div className="section-title_line" ref={div => this.staggerWords.push(div)}></div>
					</div>
					<div className="home-events_inner">
						<div className="events-left">
							<div className="txt-5 regular downcase" ref={div => this.staggerWords.push(div)}>
							 Vieni a trovarci per vedere i nostri show, conoscerci e ballare con noi!
							</div>
							<div className="events-left_follow-us">
								<div className="txt-5 regular downcase" ref={div => this.staggerWords.push(div)}>
									Seguici su: 
								</div>
								<a href="https://www.facebook.com/marcoedaisyteam/" rel="nofollow" target="_blank" ref={div => this.staggerWords.push(div)}>
									<i className="icon-facebook"></i>
								</a>
								<a href="https://www.instagram.com/marcoedaisy/?hl=it" rel="nofollow" target="_blank" ref={div => this.staggerWords.push(div)}>
									<i className="icon-instagram"></i>
								</a>
								<a href="https://www.youtube.com/channel/UCcx45clLRsyglGIEpZXk3DQ" rel="nofollow" target="_blank" ref={div => this.staggerWords.push(div)}>
									<i className="icon-youtube"></i>
								</a>
							</div>
							
							<div className="panel-contacts" ref={div => this.staggerWords.push(div)}>
								<div className="txt-4 regular">
									Contattaci 
								</div>
								<div className="panel-contacts_list txt-6">
									<a className="panel-contacts_list_item" href="https://wa.me/393899121794" rel="nofollow" target="_blank">
										<i className="icon-whatsapp1"></i>&nbsp;+39 3899121794 
									</a>
									<a className="panel-contacts_list_item" href="https://wa.me/393407978472" rel="nofollow" target="_blank">
										<i className="icon-whatsapp1"></i>&nbsp;+39 3407978472 
									</a>
									<a className="panel-contacts_list_item downcase" href="mailto:info.marcoedaisy@gmail.com" rel="nofollow" target="_blank">
										<i className="icon-mail"></i>&nbsp;info.marcoedaisy@gmail.com 
									</a>
								</div>
							</div>
						</div>
						
						<EventList/>

						
						
					</div>
				</div>
				
			</section>
		)
	}
}

////////////////////////////////////////
///////////// EVENT LIST ///////////////
////////////////////////////////////////

class EventList extends React.Component{
	constructor(props){
		super(props)
		this.state = {events: []}
		this.monthNames = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']
		this.eventDivs = []
	}

	getEvents(){
		const url = '/api/events'
		const params = { method: 'GET',
               headers: new Headers(),
               mode: 'cors',
               cache: 'default' };
		fetch(url, params)
			.then(response => response.json())
			.then(data => this.setState({events: data}))
			.then(() => this.setScrollMagic())
	}

	setScrollMagic(){
		this.smController = new ScrollMagic.Controller()
		const smScene1 = new ScrollMagic.Scene({
			triggerElement: this.eventDivs[0],
			triggerHook: 0.8,
			reverse: false
		})

		const tween1 = TweenMax.staggerFromTo(this.eventDivs, 0.5, {opacity:0, x: -100}, {opacity:1, x: 0, ease: Back.easeInOut}, 0.1)

		smScene1.setTween(tween1)
				.addTo(this.smController)
	}

	componentDidMount(){
		this.getEvents()
	}

	componentDidUpdate(){	}

	monthName(date_coded){
		let date = Date.parse(date_coded)
		date = new Date(date)
		return this.monthNames[date.getMonth()]
	}

	monthNameShort(date_coded){
		let date = Date.parse(date_coded)
		date = new Date(date)
		let monthName = this.monthNames[date.getMonth()]
		return monthName.substring(0,3)
	}
	dayNumber(date_coded){
		let date = Date.parse(date_coded)
		date = new Date(date)
		let number = date.getDate()
		if (number < 10){
			number = '0' + number
		}
		return number
	}

	render(){
		if(this.state.events.length == 0){
			return(<div className="events-list">
						<div className="txt-3">
							Al momento non abbiamo eventi a calendario, seguici per saperne di più
						</div>
					</div>
			)
		}else{
			return(
				<div className="events-list">
					{this.state.events.map(event => {return(
						<Link to={`/eventi/${event.slug}`} ref={div => this.eventDivs.push(div)} key={`event-${event.id}`}  className="events-list_item">
								<div className="events-list_item_date">
									<div className="events-list_item_date_day">
										{this.dayNumber(event.start_date)} 
									</div>
									<div className="events-list_item_date_month">
										&nbsp;| {this.monthName(event.start_date)}
									</div>
									<div className="events-list_item_date_city">
										{event.city}
									</div>
								</div>
								<div className="events-list_item_date-mobile">
									<div className="events-list_item_date-mobile_day">
										{this.dayNumber(event.start_date)} 
									</div>
									<div className="events-list_item_date-mobile_month">
										{this.monthNameShort(event.start_date)}
									</div>
								</div>
								<div className="events-list_item_main">
									<div className="regular events-list_item_main_city">
										{event.city}
									</div>
									<div className="regular events-list_item_main_title">
										{event.title}
									</div>
									<div className="regular events-list_item_main_venue">
										{event.venue}
									</div>
								</div>
								<div className="events-list_item_chevron">
									<img src="/contents/right-chevron.svg" alt="right-chevron"/>
								</div>
							</Link>

					)})}
				</div>	
			)
		}
		
			
	}
}

////////////////////////////////////////
///////////// CONTACT US ///////////////
////////////////////////////////////////

// class ContactUsCircle extends React.Component{


// 	setLetters(){
// 		const lettersArr = this.contactUs.innerHTML.split('')
// 		this.contactUs.innerHTML = ''
// 		for(let i = 0; i < lettersArr.length; i++){
// 			let x = document.createElement('span')
// 			x.classList.add('char-' + i)
// 			x.innerHTML = lettersArr[i]
// 			this.contactUs.appendChild(x)
// 		}
// 	}

// 	componentDidMount(){
// 		this.setLetters()
// 	}

// 	render(){
// 		return(
// 			<div className="contact-us-circle" ref={div => this.contactUs = div}>
// 				Contattaci - Contattaci -
// 			</div>
// 		)
// 	}
// }
// <a href="" ref={div => this.eventDivs.push(div)} key={`event-${event.id}`}  className="events-list_item">
// 								<div className="events-list_item_date">
// 									<div className="events-list_item_date_day">
// 										{this.dayNumber(event.start_date)} 
// 									</div>
// 									<div className="events-list_item_date_month">
// 										&nbsp;| {this.monthName(event.start_date)}
// 									</div>
// 									<div className="events-list_item_date_city">
// 										{event.city}
// 									</div>
// 								</div>
// 								<div className="events-list_item_date-mobile">
// 									<div className="events-list_item_date-mobile_day">
// 										{this.dayNumber(event.start_date)} 
// 									</div>
// 									<div className="events-list_item_date-mobile_month">
// 										{this.monthNameShort(event.start_date)}
// 									</div>
// 								</div>
// 								<div className="events-list_item_main">
// 									<div className="regular events-list_item_main_city">
// 										{event.city}
// 									</div>
// 									<div className="regular events-list_item_main_title">
// 										{event.title.substring(0, 30)}
// 									</div>
// 									<div className="regular events-list_item_main_venue">
// 										{event.venue.substring(0, 30)}
// 									</div>
// 								</div>
// 								<div className="events-list_item_chevron">
// 									<img src="/contents/right-chevron.svg"/>
// 								</div>
// 							</a>

