import React from 'react'
import ReactDOM from 'react-dom'
import {Route, Link, BrowserRouter, HashRouter} from 'react-router-dom'

import App from '../components/App'



document.addEventListener('DOMContentLoaded', () => {
	ReactDOM.render(
		<BrowserRouter>
			<App/>
		</BrowserRouter>, 
		document.getElementById('root')
	)
})


// https://greensock.com/forums/topic/18138-animated-page-transitions-in-react-using-gsap/