// import CSSPlugin from 'gsap/CSSPlugin'
import {TweenMax, TweenLite} from 'gsap'
import ScrollMagic from '../lib/ScrollMagic.js'
// const myPlugins = [CSSPlugin]

function setParallax(container, target, trigger, duration, Ystart, Yend){
	const controller = new ScrollMagic.Controller()
	const parallaxScene = new ScrollMagic.Scene({
			triggerElement: container,
			triggerHook: trigger,
			duration: duration 
		})
		.setTween(TweenMax.fromTo(target, 1, {y: Ystart}, {y: Yend}))
		.addTo(controller)
}

function lazyLoad(){
}

export {setParallax, lazyLoad}

