import React from 'react'

export default class CookiePolicy extends React.Component{
	render(){
		return (<div className="main-container about align-left">
					<div className="small-container">
						<div className="page-title">Cookie Policy</div>
						<div className="txt-6 bold">
							Premesse
						</div>
						<div className="txt-6 margin-bottom-2 downcase">
							Definizioni<br/> Nell’ambito del presente documento, i termini convenzionalmente indicati con la lettera maiuscola (siano essi al singolare o al plurale), se non diversamente specificato, hanno il significato esposto nell’Informativa per clienti (“Informativa”) pubblicata sul sito web del Titolare (www.marcoedaisy.com, “Sito”).
						</div>
						<div className="txt-6 margin-bottom-2 downcase">
							Titolare del trattamento<br/> Con la Policy Marco Martignani, in qualità di titolare del trattamento, adempie l’obbligo di fornire ai navigatori del Sito (“Interessati”) le informazioni relative al trattamento dei dati personali acquisiti automaticamente mediante il Sito.
						</div>
						<div className="txt-6 margin-bottom-2 downcase">
							Contesto normativo.<br/> La presente Policy è rilasciata: (i) sul piano dell’Unione Europea, in forza dell’articolo 5 della Direttiva 2002/58/CE, modificata dalla Direttiva 2009/136/CE del Parlamento europeo e del Consiglio e del GDPR; (ii) sul piano delle Leggi Nazionali sulla Protezione dei Dati, in forza dell’articolo 122 del Decreto Legislativo 196/2003, come modificato dal Decreto Legislativo 101/2018, nonché del Provvedimento n.229/2014 dell’Autorità garante per la protezione dei dati personali in materia di cookie, consultabili ai seguenti link:<br/>
							http://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/35284
							https://www.garanteprivacy.it/web/guest/regolamentoue<br/>
							https://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/9042678<br/>
							http://www.garanteprivacy.it/web/guest/home/docweb/-/docweb-display/docweb/3118884
						</div>
						<div className="txt-6 margin-bottom-2 downcase">
							Modifiche e revisioni.<br/>
							La Policy può subire modifiche/revisioni nel tempo, per diverse ragioni, tra cui, a titolo esemplificativo, eventuali modifiche e/o revisioni della disciplina di riferimento a livello nazionale e/o dell’Unione Europea e esigenze aziendali. In caso di modifica saranno pubblicate sul Sito le nuove versioni della Policy con numerazione progressiva (“01”, “02”, ecc.). Le nuove versioni saranno applicabili agli Interessati dalla data di pubblicazione.
						</div>
						<div className="txt-6 margin-bottom-4 downcase">
							Rinvio.<br/>
							La Policy integra quanto previsto dall’Informativa e dagli altri messaggi informativi eventualmente pubblicati sul Sito.
						</div>
						<div className="txt-6 bold">
							Cosa sono i cookie?
						</div>
						<div className="txt-6 margin-bottom-2 downcase">
							Informazioni generali sui cookie<br/>  I cookie sono piccoli file di testo, raccolti automaticamente durante l’utilizzo del Sito e archiviati nei dispositivi utilizzati dagli Interessati per la navigazione sul Sito, dove vengono memorizzati per essere ritrasmessi durante la successiva navigazione e a ogni utilizzo del Sito.
						</div>
						<div className="txt-6 margin-bottom-4 downcase">
							Classificazione<br/>  I cookie sono necessari per la gestione del Sito e l’erogazione di alcuni servizi da parte del Titolare, per consentirne l’utilizzo agli Interessati e, soprattutto, per rendere migliore l’esperienza di navigazione. I cookie si distinguono a seconda della tipologia e delle finalità di utilizzo, come di seguito descritte.
						</div>
						<div className="txt-6 bold">
							Tipologia, finalità e disattivazione dei cookies utilizzati dal Titolare
						</div>
						<div className="txt-6 margin-bottom-2 downcase">
							Cookie tecnici<br/> 
							Il Sito utilizza cookies tecnici di navigazione e di funzionalità, per attività strettamente necessarie a consentire il funzionamento e l’utilizzo del Sito agli Interessati. I cookies di navigazione sono essenziali per consentire agli Interessati di accedere alle varie aree del Sito. I cookies di funzionalità sono utilizzati al fine di consentire agli Interessati di avvalersi di alcune funzioni del Sito essenziali ai fini dell’erogazione dei servizi.
						</div>
						<div className="txt-6 margin-bottom-2 downcase">
							Cookie analitici di terze parti<br/> 
							Il Sito utilizza cookie analitici di terze parti per individuare quali parti, elementi o servizi del Sito siano maggiormente apprezzati dagli Interessati. Si tratta del servizio di Google Analytics, che fornisce uno strumento di analisi anonima e aggregata delle informazioni derivanti dall’utilizzo effettuato da parte degli Interessati del Sito. Questo cookie non è di proprietà del Titolare, pertanto, per maggiori informazioni sulle relative funzionalità si invitano gli Interessati a consultare l’informativa fornita da Google Inc. ai seguenti indirizzi:<br/>
							http://www.google.it/policies/privacy/partners/
						</div>
						<div className="txt-6 margin-bottom-2 downcase">
							Disattivazione cookies analitici di terze parti<br/> 
							Gli Interessati possono decidere di non consentire l’utilizzo del cookie analitici di terze parti scegliendo alternativamente le seguenti soluzioni:
							- utilizzare la navigazione anonima del browser. Tale soluzione impedisce l’utilizzo di qualsiasi cookie proveniente da terze parti (non solo da Google Inc.). Per comodità rinviamo alle seguenti pagine dei principali motori di ricerca dalle quali è possibile attivare/disattivare la navigazione anonima: Internet Explorer, Google Chrome, Firefox, Apple Safari.<br/>
							- disattivare esclusivamente il cookie Google Analytics installando sul proprio browser l’apposito componente aggiuntivo rilasciato da Google e scaricabile al seguente link, https://tools.google.com/dlpage/gaoptout?hl=it
						</div>
						<div className="txt-6 margin-bottom-2 downcase">
							Altri cookies di terze parti<br/>
							 Il Sito utilizza anche i seguenti cookies:<br/>
							 - Google Maps - si tratta di un servizio fornito da Google per includere mappe interattive all’interno delle pagine web. Tale servizio comporta l’installazione di un cookie da parte di Google. Per consultare l’informativa privacy di Google e disattivazione si rinvia al seguente link: http://www.google.com/intl/it/policies/privacy/;
							- Social Buttons - sono quei particolari “pulsanti” presenti sul sito che raffigurano le icone di social network (esempio, Facebook, Linkedin, Instagram) e consentono ai navigatori di interagire con un “click” direttamente con i social network. I social buttons utilizzati dal Sito sono dei link che rinviano agli account del Titolare sui social network raffigurati. Di seguito Si riportano i link alle informative privacy relative alla gestione dei dati da parte dei Social a cui i pulsanti rinviano: https://www.facebook.com/help/cookies - https://www.youtube.com/intl/it/about/policies/#community-guidelines - https://www.instagram.com/about/legal/terms/api/
						</div>
						<div className="txt-6 margin-bottom-2 downcase">
							Informazioni generali sui cookies. <br/>
							er maggiori informazioni generali sui cookies e sulle azioni per modificarli, controllarli o eliminarli, indichiamo i seguenti link:
							- https://www.aboutcookies.org/
							- http://www.youronlinechoices.com/it/
							- https://www.networkadvertising.org/understanding-digital-advertising
							- https://www.aboutads.info/consumers/
							- https://youradchoices.ca/en/learn
						</div>
					</div>
				</div>)
	}
}