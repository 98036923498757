import React from 'react'
import {Link} from 'react-router-dom'

import CSSPlugin from 'gsap/CSSPlugin';
import {TweenMax, TimelineMax, Power4, Back} from 'gsap'
import ScrollMagic from '../lib/ScrollMagic.js'
const myPlugins = [CSSPlugin]

import {setParallax} from '../lib/utils.js'

export default class Team extends React.Component{
	constructor(props){
		super(props)
		this.staggerWords = []
	}

	setScrollMagic(){
		this.smController = new ScrollMagic.Controller()
		const smScene1 = new ScrollMagic.Scene({
			triggerElement: this.sectionDiv,
			triggerHook: 1,
			reverse: false
		})
		const tween1 = TweenMax.staggerFromTo(this.staggerWords, 1, {opacity:0, y: 30}, {opacity:1, y: 0, ease: Back.easeInOut}, 0.2)
		smScene1.setTween(tween1)
				.addTo(this.smController)
	}

	// setParallax(){
	// 	const parallaxScene = new ScrollMagic.Scene({
	// 		triggerElement: this.sectionDiv,
	// 		triggerHook: 1,
	// 		duration: "300%"
	// 	})
	// 	.setTween(TweenMax.fromTo(this.sectionBg, 1, {y: '-20%'}, {y: '40%', ease: Power4.easeNone}))
	// 	.addTo(this.smController)
	// }

	componentDidMount(){
		this.setScrollMagic()
		// setParallax(this.sectionDiv, this.sectionBg, '1', '200%', '-20%', '60%'); 
	}

	render(){
		return(
			<section id="home-team" className="team" ref={div => this.sectionDiv = div}>
				<div className="team-bg" ref={div => this.sectionBg = div}>
					<img className="image-darken-dark" src="/contents/team-bg.png" alt="marco-e-daisy-team"/>
				</div>
				<p className="small-container">
					<span className="section-title">
						Gare
						<span className="underline" ref={div => this.staggerWords.push(div)}></span>
					</span><br/>
					<span className="txt-3 downcase margin-bottom-4">
					 	Mettiti alla prova ed entra anche tu a far parte del Marco & Daisy team! 
					</span><br/>
					<span className="txt-3 downcase margin-bottom-4">
					 	Potrai misurarti in  competizioni nazionali ed internazionali con noi.
					</span><br/>
					<span className="txt-3 downcase margin-bottom-4">
					 	Ti seguiremo sia dal lato danzante, grazie ai nostri anni in gara ad alto livello, sia dal lato della preparazione atletica, essendo esperti e appassionati di scienze motorie.
					</span><br/>

				</p>
			</section>
		)
	}
}